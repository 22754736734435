import { defineStore } from 'pinia'

interface dashboardConfig {
  [key: string]: any
}

interface device {
  [key: string]: any
}

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      isSidebarMinimized: false,
      ireboxUUID: localStorage.getItem('ireboxUUID') || '',
      inverterType: localStorage.getItem('inverterType') || '',
      dashboardConfig: {} as dashboardConfig,
      devices: [] as device[],
    }
  },

  actions: {
    toggleSidebar() {
      this.isSidebarMinimized = !this.isSidebarMinimized
    },

    getDevices() {
      return this.devices
    },

    setDevices(devices: any) {
      this.devices = devices
    },

    getDashboardConfig() {
      return this.dashboardConfig
    },

    setDashboardConfig(dashboardConfig: any) {
      this.dashboardConfig = dashboardConfig
    },

    getIreboxUUID() {
      return this.ireboxUUID
    },

    setIreboxUUID(ireboxUUID: string) {
      localStorage.setItem('ireboxUUID', ireboxUUID)
      this.ireboxUUID = ireboxUUID
    },

    getInverterType() {
      return this.inverterType
    },

    getInverterTypeByID(ireboxUUID: string) {
      return this.devices.find((device) => device.irebox === ireboxUUID)?.inverter_type
    },

    hasSchedulerEnabled(ireboxUUID: string) {
      return this.devices.find((device) => device.irebox === ireboxUUID)?.scheduler
    },

    setInverterType(inverterType: string) {
      localStorage.setItem('inverterType', inverterType)
      this.inverterType = inverterType
    },

    isMultipartInverter(ireboxUUID: string) {
      return this.devices.find((device) => device.irebox === ireboxUUID)?.powerflow_mapping.multipart || false
    },

    getMapping(ireboxUUID: string) {
      return this.devices.find((device) => device.irebox === ireboxUUID)?.powerflow_mapping
    },
  },
})
